<template>
    <div>
        <div class="h-handle-bg">
            <div class="h-handle-button">
                <p class="h-return"><el-button type="text" size="small" @click="$parent.checkSaveStatus">返回</el-button> </p>
                <div class="h-b">
                    <el-button type="primary" size="small" @click="add" v-right-code="'Systemtemplate:Save'">新增</el-button>
                </div>
                <div class="h-b">
                    <el-button type="primary" size="small" @click="save" v-right-code="'Systemtemplate:Save'">保存</el-button>
                </div>
            </div>
        </div>

        <div class="form-list">
            <el-form :model="dataSource" ref="_basicForm" :rules="baseRule">
                <el-form-item>
                    <el-col :span="3" class="form-title">
                        <span style="color:red;">*</span> 模板编码：
                    </el-col>
                    <el-col :span="7">
                        <el-form-item prop="MsgTplCode">
                            <el-input v-model="dataSource.MsgTplCode" :maxlength="50"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title">
                        <span style="color:red;">*</span> 模板名称：
                    </el-col>
                    <el-col :span="7">
                        <el-form-item prop="MsgTplName">
                            <el-input v-model="dataSource.MsgTplName"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">
                        <span style="color:red;">*</span> 模板状态：
                    </el-col>
                    <el-col :span="7">
                        <el-form-item prop="MsgTplStatus">
                            <DictionarySelect 
                                v-model="dataSource.MsgTplStatus" 
                                datType='Int' 
                                dicTypeCode="CommonStatusCode">
                            </DictionarySelect>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">
                        模板内容：
                    </el-col>
                    <el-col :span="17">
                        <el-form-item prop="MsgTplContent">
                            <el-input type="textarea" :rows="15" v-model="dataSource.MsgTplContent" :maxlength="500"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">
                        推送渠道：
                    </el-col>
                    <el-col :span="7">
                        <el-form-item prop="PushType">
                            <el-checkbox v-model="dataSource.PushType" disabled>站内信</el-checkbox>
                        </el-form-item>
                    </el-col>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            baseRule: {
                MsgTplCode: [{
                    required: true,
                    message: '请输入模板编码',
                    trigger: 'blur'
                }],
                MsgTplName: [{
                    required: true,
                    message: '请输入模板名称',
                    trigger: 'blur'
                }],
                MsgTplStatus: [{
                    required: true,
                    message: '请选择模板状态',
                    trigger: 'blur'
                }],
                MsgTplContent: [{
                    required: true,
                    message: '请输入模板内容',
                    trigger: 'blur'
                }]
            },
        }
    },
    props: {
        config: {
            isDetailDisplay: false,
        },
        dataSource: {}
    },
    methods: {
        add(){
            this.Event.$emit(this.config.moduleEvent.add);
        },
        save(){
            var _this = this
            _this.$refs['_basicForm'].validate((valid) => {
                _this.isPassValidate = valid
                if (valid) {
                    _this.$ajax.send(
                        'omsapi/systemtemplate/save',
                        'post',
                        _this.dataSource,
                        (data) => {
                            _this.$parent.syncDataSource(data.Result);
                            _this.Event.$emit(this.config.moduleEvent.initial);
                            _this.Utils.messageBox('保存成功.', 'success')
                        }
                    )
                } else {
                    return false
                }
            })
        }
    }
}
</script>
